import React from 'react';
import { useRootContext } from 'src/contexts/Root';
import AssetWidget from './components/AssetWidget';
import AvatarMenuWidget from './components/AvatarMenuWidget';
import ButtonWidget from './components/ButtonWidget';
import InformationWidget from './components/InformationWidget';
import ImageWidget from './components/ImageWidget';
import AppNameWidget from './components/AppNameWidget';
import LogoFactory from 'src/components/factories/LogoFactory';
import * as T from './types';

// TODO: ADD code-split
// TODO: Create a mapper to automatic inject new widgets
const WidgetFactory: T.WidgetFactoryType = ({ criterionKey, ...props }) => {
  const { criterion } = useRootContext();
  const isAllowedToRender = criterionKey
    ? criterion.useReactCheckByCriterionKey(React, criterionKey)
    : true;

  if (isAllowedToRender) {
    if (props?.asset?.enable) {
      return <AssetWidget {...props?.asset} />;
    } else if (props?.avatarMenu?.enable) {
      return <AvatarMenuWidget {...props?.avatarMenu} />;
    } else if (props?.button?.enable) {
      return <ButtonWidget {...props?.button} />;
    } else if (props?.information?.enable) {
      return <InformationWidget {...props?.information} />;
    } else if (props?.logo?.enable) {
      return <LogoFactory {...props?.logo} />;
    } else if (props?.image?.enable) {
      return <ImageWidget {...props?.image} />;
    } else if (props?.appName?.enable) {
      return <AppNameWidget {...props?.appName} />;
    }
  }

  return null;
};

export default WidgetFactory;
