import styled from 'styled-components';
import { Header, HeaderProps } from '@veneer/core';

type HeaderHeightPropType = {
  headerHeight: string;
  isWindowsApp?: boolean;
};
type HeaderPropsType = HeaderHeightPropType & HeaderProps;

export const Container = styled(Header)<HeaderPropsType>`
  min-height: ${({ headerHeight }) => headerHeight} !important;
  height: ${({ headerHeight }) => headerHeight} !important;
  max-height: ${({ headerHeight }) => headerHeight} !important;
`;
