import React from 'react';
import { useRootContext } from 'src/contexts/Root';
import { ProgressIndicator } from '@veneer/core';
// import getCurrentPermissions from 'src/services/v3/getCurrentPermissions';
import * as S from './styles';
import * as T from './types';

// TODO: Check the need of this, since the only use is for Admin users
const RoleBadge: T.RoleBadgeType = ({
  fallbackLabel
  // labels
}) => {
  const { criterion } = useRootContext();

  const isAllowedToRender = criterion.useReactCheck(React, {
    type: 'isLoggedIn',
    value: true
  });

  const roleName = fallbackLabel;
  // const roleName = useMemo(() => {
  //   // Todo:  Check the requirements for this issue
  //   // getCurrentPermissions(stack, authProvider).then((permissions) => {
  //   //   const displayName = permissions?.type;
  //   //if (displayName)
  //   //  setRoleName(roleBadge);
  //   // });

  //   return fallbackLabel
  // }, [isAllowedToRender, fallbackLabel, labels]);

  if (isAllowedToRender) {
    return (
      <S.Container>
        {roleName ? roleName : <ProgressIndicator appearance="circular" />}
      </S.Container>
    );
  }

  return null;
};

export default RoleBadge;
