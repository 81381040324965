/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@veneer/core';
import SelectableButton from 'src/components/SelectableButton';
import getCurrentUser from 'src/services/usermgt/getCurrentUser';
import { useRootContext } from 'src/contexts/Root';
import { UserDataPropsType } from './components/UserData/types';
import logger from 'src/interfaces/logger';
import * as S from './styles';
import * as T from './types';
import * as C from './components';
import OrgAvatarButton from 'src/components/factories/WidgetFactory/components/AvatarMenuWidget/components/OrgAvatarButton';
import useAvatarMenuListFilter from '../../hooks/useAvatarMenuListFilter';
import createId from 'src/utils/createId';
import { useDirectionContext } from 'src/contexts/Direction';
import IconFactory from 'src/components/factories/IconFactory';
import { Stack } from 'src/types/stratus';

const ProfileMenu = ({
  menuList,
  orgSelector,
  hideOrgName
}: T.ProfileMenuPropsType) => {
  const {
    localization,
    tenantHandlerInterface,
    sessionInterface,
    userInterface,
    stack
  } = useRootContext();
  const { filteredMenuList, logoutMenu } = useAvatarMenuListFilter(menuList);
  const [user, setUser] = useState<UserDataPropsType['user'] | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [showAllDomains, setShowAllDomains] = useState(false);
  const [isModalBlocked, setIsModalBlocked] = useState(false);
  const [, setIgnoreClick] = useState(false);
  const containerRef = useRef<HTMLDivElement>();
  // TODO: Need a better approach to get tenant list when it is not initialized yet
  const [isOrgSelectorEnabled, setIsOrgSelectorEnabled] = useState(false);
  const { isRTL } = useDirectionContext();

  const isUserViewEnabled =
    !!isOrgSelectorEnabled &&
    !showAllDomains &&
    !!orgSelector?.userView?.enable;

  const { t } = localization.useReactTranslatorHook();

  useEffect(() => {
    const isOrgSelectorEnabledBeforeInit =
      orgSelector?.enable && tenantHandlerInterface?.isEnabled?.();
    if (!isOrgSelectorEnabled && isOrgSelectorEnabledBeforeInit) {
      setIsOrgSelectorEnabled(true);
    } else if (isOrgSelectorEnabled !== isOrgSelectorEnabledBeforeInit) {
      setIsOrgSelectorEnabled(isOrgSelectorEnabledBeforeInit);
    }
  }, [isOrgSelectorEnabled, orgSelector?.enable]);

  useEffect(() => {
    getCurrentUser(userInterface)
      .then((user) => {
        setUser({
          email: user?.email?.value as string,
          givenName: user?.givenName as string,
          familyName: user?.familyName as string
        });
      })
      .catch((err) => {
        logger?.error?.(err);
      });
  }, []);

  useEffect(() => {
    if (showModal && !isModalBlocked) {
      const handleClick = (event: MouseEvent) => {
        const clickedNode = event.target as HTMLElement;
        const container = containerRef?.current;

        setIgnoreClick((prev) => {
          if (!prev && !container?.contains?.(clickedNode)) {
            setShowModal(false);
          }
          return false;
        });
      };
      window.addEventListener('click', handleClick);

      return () => window.removeEventListener('click', handleClick);
    }
  }, [showModal, isModalBlocked]);

  return (
    <S.Container ref={containerRef as any}>
      <OrgAvatarButton
        user={user}
        setShowModal={setShowModal}
        showModal={showModal}
        hideOrgName={hideOrgName}
      />
      {showModal && (
        <S.MenuModal
          isRTL={isRTL}
          appearance="dropShadow"
          content={
            <S.ModalContentWrapper data-testid={createId('menu_modal')}>
              {/* Back */}
              {showAllDomains && (
                <div>
                  <SelectableButton
                    leftIcon={() => (
                      <IconFactory iconReference="IconChevronLeft" />
                    )}
                    onClick={() => {
                      setIgnoreClick(true);
                      setShowAllDomains(false);
                    }}
                  >
                    {/* TODO: Check how we are going to localize it, should it come from property? */}
                    {t('nav.profile.back', 'Back')}
                  </SelectableButton>
                </div>
              )}
              {/* User Data section */}
              {!showAllDomains && (
                <C.UserData
                  menuList={filteredMenuList}
                  user={user}
                />
              )}
              {/* Domain Data section */}
              {isOrgSelectorEnabled && (
                <C.Domains
                  {...{
                    onModalToggle: setIsModalBlocked,
                    setIgnoreClick,
                    setShowAllDomains,
                    showAllDomains,
                    customIcons: orgSelector?.customIcons,
                    defaultIcon: orgSelector?.defaultIcon,
                    filled: orgSelector?.fillSelected,
                    useChevron: orgSelector?.useChevron,
                    orgCreation: orgSelector?.orgCreation
                  }}
                />
              )}
              {/* User View section */}
              {isUserViewEnabled && (
                <S.UserViewWrapper>
                  <SelectableButton
                    leftIcon={() => <IconFactory iconReference="IconPerson" />}
                    rightIcon={() => (
                      <IconFactory iconReference="IconChevronRight" />
                    )}
                  >
                    {/* TODO: Check how we are going to localize it, should it come from property? */}
                    {t('nav.profile.open-user-view', 'Open User View')}
                  </SelectableButton>
                </S.UserViewWrapper>
              )}
              {/* Logout section */}
              <S.LogoutWrapper>
                <Button
                  onClick={() => {
                    sessionInterface.logout({
                      postLogoutRedirect: `https://www.hpsmart${stack === Stack.prod ? '' : Stack[stack]}.com/signout`
                    });
                  }}
                  appearance="secondary"
                  expanded
                  data-testid={createId('logout_button')}
                >
                  {/* TODO: Check how we are going to localize it, should it come from property? */}
                  {logoutMenu?.text?.value}
                </Button>
              </S.LogoutWrapper>
            </S.ModalContentWrapper>
          }
        />
      )}
    </S.Container>
  );
};

export default ProfileMenu;
