import styled from 'styled-components';
import tokens from '@veneer/tokens';
import { isRTLType, themeModeType } from 'src/types/commonPropertiesType';
import { getColor } from 'src/components/factories/utils/styles';

export const Container = styled.div<
  {
    isOrgAvatarButtonShowable: boolean;
  } & isRTLType
>`
  display: flex;
  box-shadow: ${({ isOrgAvatarButtonShowable }) =>
    isOrgAvatarButtonShowable
      ? '0px 0px 0px 1px rgba(2, 122, 174, 0.1)'
      : 'none'};
  border-right: none;

  border-radius: ${({ isOrgAvatarButtonShowable, isRTL }) => {
    if (!isOrgAvatarButtonShowable) return 'none;';
    if (isRTL) return '18px 8px 8px 18px;';
    return '8px 18px 18px 8px;';
  }};
  align-items: center;
  gap: ${tokens.layout.size2};
`;

export const OrgName = styled.p<isRTLType & themeModeType>`
  font-weight: bold;
  font-size: ${tokens.typography.size0};
  line-height: ${tokens.typography.lineHeight1};
  letter-spacing: 0.04em;
  color: ${({ themeMode }) =>
    getColor({
      defaultColor: tokens.color.gray7,
      defaultHighContrastColor: tokens.color.highContrastGray,
      themeMode: themeMode
    })};

  ${({ isRTL }) => {
    if (isRTL) return `margin-right: ${tokens.layout.size2};`;
    return `margin-left: ${tokens.layout.size2};`;
  }}
`;
