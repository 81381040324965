import { useEffect } from 'react';
import { useRootContext } from 'src/contexts/Root';
import * as T from './types';

export default function useAutoScrollUpOnRouteChange({
  containerId
}: T.UseAutoScrollUpOnRouteChangePropsType) {
  const { navigation } = useRootContext();

  useEffect(() => {
    const scrollUp = () => {
      const container = document.getElementById(containerId);
      container?.scrollTo?.(0, 0);
    };
    scrollUp();
    let pathname = navigation.location.pathname;

    const removeListener = navigation.listen((location) => {
      if (pathname !== location.pathname) {
        pathname = location.pathname;
        scrollUp();
      }
    });

    return () => removeListener();
  }, [navigation, containerId]);
}
