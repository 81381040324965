import tokens from '@veneer/tokens';

type GetColorProps = {
  defaultColor: string;
  defaultHighContrastColor: string;
  themeMode: string;
  customColor?: string;
};

/**
 * Retrieve the proper color based on rules checking the themeMode and if
 * was passed a custom color.
 * @param defaultColor
 * @param themeMode
 * @param customColor
 * @param defaultHighContrastColor
 * @returns current color to be used.
 */
export function getColor({
  defaultColor,
  defaultHighContrastColor,
  themeMode,
  customColor
}: GetColorProps): string {
  if (themeMode === 'contrast') return defaultHighContrastColor;
  if (themeMode === 'dark') return tokens.color.white;
  if (customColor) return customColor;
  return defaultColor;
}

/**
 * Retrieve the proper font size based on rules
 * @param fontSize
 * @returns current font size to be used as string.
 */
export function getFontSize(fontSize?: number): string {
  if (typeof fontSize === 'number' && fontSize > 0) return `${fontSize}px`;
  return '12px';
}
