import styled from 'styled-components';
import { Footer, FooterProps } from '@veneer/core';
import tokens from '@veneer/tokens';

const mobileBreakPoint = tokens.layout.mdMin;

export const PortalFooter = styled(Footer)<FooterProps>`
  &,
  * {
    border: none !important;
    line-height: ${tokens.typography.lineHeight2};

    @media (max-width: ${mobileBreakPoint}) {
      flex-direction: column-reverse;
    }
  }

  & {
    @media (max-width: ${mobileBreakPoint}) {
      > div {
        height: auto;
        padding-top: 0px;

        > div:first-of-type {
          padding-top: 20px;
        }
      }
    }
  }

  a {
    text-decoration: none;
    display: inline-block;
    font-size: ${tokens.typography.size2};
  }

  a:hover {
    text-decoration: underline;
  }
`;
