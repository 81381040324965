import { useEffect, useState } from 'react';
import { useRootContext } from 'src/contexts/Root';
import { MenuType } from '../../types';

type SubMenuType = MenuType['subMenus'][number];
type UseFilterSubMenusReturnType = {
  isLoaded: boolean;
  subMenus: SubMenuType[];
};

type UseFilterSubMenusPropsType = {
  enable: boolean;
  subMenus: SubMenuType[];
};

export default function useFilterSubMenus({
  enable,
  subMenus
}: UseFilterSubMenusPropsType): UseFilterSubMenusReturnType {
  const { criterion, routes } = useRootContext();
  const [filteredSubMenus, setFilteredSubMenus] = useState<SubMenuType[]>([]);
  const [isLoaded, setIsLoaded] = useState(!enable);

  useEffect(() => {
    if (enable && Array.isArray(subMenus)) {
      const filterSubRoutes = async () => {
        const thisSubroutes: SubMenuType[] = [];
        for (const menu of subMenus) {
          const route = menu?.routeKey && routes.findRouteByKey(menu?.routeKey);
          const criterionKey = menu?.criterionKey || route?.criterionKey;

          const isAllowed = criterionKey
            ? await criterion?.checkByCriterionKey?.(criterionKey)
            : true;

          if (isAllowed) {
            thisSubroutes.push({
              iconReference: menu?.iconReference || route?.iconReference,
              path: menu?.path || route?.path,
              subMenus: menu?.subMenus,
              text: {
                value: menu?.text?.value || route?.label,
                translationKey:
                  menu?.text?.translationKey || route?.translationKey
              },
              exact: menu.exact
            });
          }
        }

        setIsLoaded(true);
        setFilteredSubMenus(thisSubroutes);
      };

      filterSubRoutes();

      const removeListener = criterion?.listen?.(filterSubRoutes);

      return () => removeListener?.();
    }
  }, [criterion, enable, subMenus]);

  return {
    isLoaded: isLoaded,
    subMenus: filteredSubMenus
  };
}
