import React from 'react';
// import ErrorComponent from 'src/components/ErrorComponent';
import useAssetImport from 'src/hooks/useAssetImport';
import * as T from './types';
import LoaderFactory from '../LoaderFactory';

// TODO: make it possible to override error component to show differently error contents
// eg: small widgets have different error than a screen error
const LazyComponentFactory: T.LazyComponentFactoryType = ({
  assetReference,
  importKey,
  properties,
  loader,
  defaultAppearance,
  rootProperties
}) => {
  const {
    asset,
    // error,
    isLoading
  } = useAssetImport(assetReference);
  const AssetComponent = asset?.[importKey || 'default'];
  const assetIsNotReactComponent =
    !isLoading && typeof AssetComponent !== 'function';
  // const assetNotFound = !error && !isLoading && !AssetComponent;

  // Need to be first to keep current asset alive while it load the next
  if (!assetIsNotReactComponent && AssetComponent) {
    return (
      <AssetComponent
        {...rootProperties}
        properties={properties}
      />
    );
  }
  // else if (error || assetNotFound || assetIsNotReactComponent) {
  //   return <ErrorComponent />;
  // }
  return (
    <LoaderFactory
      {...loader}
      defaultAppearance={defaultAppearance}
    />
  );
};

export default LazyComponentFactory;
