import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  width: calc(100% - 2 * 15px);
  margin: 10px 15px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;
