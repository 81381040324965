import React from 'react';
import AppNameTextComponent from './components/AppNameTextComponent';
import RoleBadge from './components/RoleBadge';
import * as S from './styles';
import * as T from './types';
import createId from 'src/utils/createId';

const AppNameWidget: T.AppNameWidgetType = ({ roleBadge, text }) => {
  return (
    <S.Container data-testid={createId(`app_name`)}>
      <AppNameTextComponent {...text} />
      {roleBadge?.enable && (
        <RoleBadge
          labels={roleBadge?.labels}
          fallbackLabel={roleBadge?.fallbackLabel}
        />
      )}
    </S.Container>
  );
};

export default AppNameWidget;
