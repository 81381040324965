import React from 'react';
import LazyComponentFactory from '../factories/LazyComponentFactory';
import * as S from './styles';
import * as T from './types';

const HeaderComponent: T.HeaderComponentType = ({
  leftContent,
  rightContent,
  customStyle,
  isWindowsApp,
  headerHeight = '80px'
}) => {
  let appearance = {};

  appearance = { appearance: customStyle?.appearance || {} };

  return (
    <S.Container
      headerHeight={headerHeight}
      position="relative"
      data-testid={'jshell-header'}
      leadingArea={leftContent}
      trailingArea={rightContent}
      isWindowsApp={isWindowsApp}
      {...appearance}
    />
  );
};

const HeaderWrapper: T.HeaderWrapperType = ({ asset, ...props }) => {
  if (asset?.assetReference) {
    return (
      <LazyComponentFactory
        assetReference={asset.assetReference}
        properties={asset?.properties}
        defaultAppearance="linear"
      />
    );
  } else {
    return <HeaderComponent {...props} />;
  }
};

export default HeaderWrapper;
