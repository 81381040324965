import { useState, useEffect, useMemo } from 'react';
import { useRootContext } from 'src/contexts/Root';
import { AvatarMenu } from '../../types';

type UseAvatarMenuListFilterReturnType = {
  logoutMenu: AvatarMenu;
  filteredMenuList: AvatarMenu[];
  menusWithLogout: AvatarMenu[];
};

/**
 * It handle criterion filter and translation
 */
export default function useAvatarMenuListFilter(
  menuListProp: AvatarMenu[]
): UseAvatarMenuListFilterReturnType {
  const {
    criterion,
    localization,
    routes: routesInterface,
    globalTranslate
  } = useRootContext();
  const { t } = localization.useReactTranslatorHook();
  const [filteredMenuList, setFilteredMenuList] = useState<AvatarMenu[]>([]);

  useEffect(() => {
    if (!criterion?.checkByCriterionKey) {
      setFilteredMenuList(menuListProp);
    } else if (Array.isArray(menuListProp)) {
      let promiseId = 0;
      const handleMenuList = async () => {
        promiseId++;
        const thisPromiseId = promiseId;
        const menuPromiseList = menuListProp.map(async (menu) => {
          const thisMenuRoute = routesInterface?.findRouteByKey(menu?.routeKey);
          const thisMenuRoutePath = Array.isArray(thisMenuRoute?.path)
            ? thisMenuRoute?.path?.[0]
            : thisMenuRoute?.path;

          const thisMenu: AvatarMenu = {
            text: {
              value: globalTranslate(
                menu?.text?.translationKey || thisMenuRoute?.translationKey,
                menu?.text?.value || thisMenuRoute?.label
              )
            },
            path: menu?.path || thisMenuRoutePath,
            //@ts-expect-error check this later
            criterionKey: menu?.criterionKey || thisMenuRoute?.criterionKey
          };
          if (thisMenu?.criterionKey) {
            const isAllowed = await criterion?.checkByCriterionKey?.(
              thisMenu.criterionKey
            );
            if (isAllowed) return thisMenu;
            else return undefined;
          } else {
            return thisMenu;
          }
        });
        const menuListWithUndefinedIndexes = await Promise.all(menuPromiseList);
        const filteredMenus = menuListWithUndefinedIndexes.filter(
          (v) => !!v
        ) as AvatarMenu[];

        if (promiseId === thisPromiseId) {
          setFilteredMenuList(filteredMenus);
        }
      };
      handleMenuList();
      const removeListener = criterion?.listen?.(handleMenuList);

      return () => removeListener?.();
    }
  }, [criterion, globalTranslate, menuListProp, routesInterface]);

  const logoutMenu: AvatarMenu = useMemo(
    () => ({
      text: {
        value: t('nav.profile.sign-out', 'Sign Out')
      },
      path: '/loggedout'
    }),
    [t]
  );

  const result = useMemo(
    () => ({
      logoutMenu,
      filteredMenuList,
      menusWithLogout: [...filteredMenuList, logoutMenu]
    }),
    [filteredMenuList, logoutMenu]
  );

  return result;
}
