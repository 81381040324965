export default {
  email: {
    value: 'jshell.test@hp.com',
    verified: true
  },
  givenName: 'Jshell',
  familyName: 'Test',
  idpType: 'idpType',
  locale: 'pt-br',
  phoneNumber: {
    value: 'phoneNumber',
    verified: true
  },
  userName: 'jshell'
};
