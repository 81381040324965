import styled from 'styled-components';
import tokens from '@veneer/tokens';

export const Container = styled.p`
  border-radius: ${tokens.layout.cornerRadius1};
  padding: 4px 8px 3px;
  background: ${tokens.color.royalBlue7};
  text-align: center;
  color: ${tokens.color.white};
  text-transform: lowercase;
  font-weight: 500;
  font-size: ${tokens.typography.size1};
  line-height: ${tokens.typography.lineHeight2};
  display: flex;
`;
