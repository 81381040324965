import { match } from 'path-to-regexp';

export default function matchPath(
  path: string | string[],
  options?: {
    exact?: boolean;
    /**
     * If it is not a string, it will be used the current location instead
     */
    pathToCompare?: string | string[];
  }
) {
  if (!path) {
    return false;
  }
  const arrayPath = Array.isArray(path) ? path : [path];
  const pathToCompare = options?.pathToCompare || window.location.pathname;
  const arrayPathToCompare = Array.isArray(pathToCompare)
    ? pathToCompare
    : [pathToCompare];

  return arrayPath.some((thisPath) => {
    const checkPath = match(thisPath, {
      encode: encodeURI,
      decode: decodeURI,
      start: true,
      end:
        typeof options?.exact === 'boolean' ? options?.exact : thisPath === '/'
    });

    return arrayPathToCompare.some(
      (pathToCompare) => !!checkPath(pathToCompare)
    );
  });
}
