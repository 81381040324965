import React, { useEffect, useState } from 'react';
import { AppFC } from 'src/types/customReactTypes';
import logger from 'src/interfaces/logger';
import * as T from './types';
import { defaultIcons, defaultIconsAvailable } from './defaultIcons';
import createId from 'src/utils/createId';

const IconFactory: T.IconFactoryType = ({
  iconReference,
  filled,
  forceFilled,
  ...props
}) => {
  const [Icon, setIcon] =
    useState<AppFC<Omit<T.IconFactoryPropsType, 'iconReference'>>>();

  useEffect(() => {
    const filename = iconReference
      ?.replace(/([A-Z])([A-Z][a-z])|([a-z0-9])([A-Z])/g, '$1$3_$2$4')
      .toLowerCase();

    const isDefaultIcon = defaultIconsAvailable.indexOf(iconReference) > -1;

    if (isDefaultIcon) {
      setIcon(defaultIcons[iconReference]);
      return;
    }

    if (filename) {
      import(
        /*webpackMode: "lazy-once", webpackChunkName: "icons-bundle"*/ `@veneer/core/dist/esm/scripts/icons/${filename}.js`
      )
        .then((icon) => {
          setIcon(icon?.default);
        })
        .catch((error) => {
          logger?.error?.('Error on fetching icon:', error);
        });
    }
  }, [iconReference]);

  if (Icon) {
    return (
      <Icon
        filled={forceFilled || filled}
        data-testid={createId('avatar-menu-icon')}
        {...props}
      />
    );
  }
  return null;
};

export default IconFactory;
