import React from 'react';
import LazyComponentFactory from 'src/components/factories/LazyComponentFactory';
import { useRootContext } from 'src/contexts/Root';
import * as T from './types';
import * as S from './styles';

const AssetWidget: T.AssetWidgetType = ({
  assetReference,
  importKey,
  loader,
  properties
}) => {
  const rootProperties = useRootContext();

  return (
    <S.Container>
      <LazyComponentFactory
        assetReference={assetReference}
        importKey={importKey}
        loader={loader}
        properties={properties}
        defaultAppearance="skeleton"
        rootProperties={rootProperties}
      />
    </S.Container>
  );
};

export default AssetWidget;
