import styled from 'styled-components';
import { SideMenu as VeneerSideMenu, SideMenuProps } from '@veneer/core';
import tokens from '@veneer/tokens';
import { isRTLType } from 'src/types/commonPropertiesType';

type HeaderHeightPropType = {
  headerHeight: string;
};

export const Container = styled.div<HeaderHeightPropType>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: left;

  .vn-side-menu__toggle {
    top: auto;
  }

  & div[role='button'] {
    padding-right: 0px;
  }
`;

export const WidgetContainer = styled.div`
  & > div:first-of-type {
    border-top: 1px solid ${tokens.color.gray2};
    overflow: hidden;
    max-width: 100%;
  }
`;

export const LeadingAreaContentWithoutMenu = styled.div`
  height: 36px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 12px;
`;

export const BreadcrumbWrapper = styled.div<
  isRTLType & { responsiveBreakpoint: string } & { iconsOnly: boolean }
>`
  @media (max-width: ${({ responsiveBreakpoint }) => responsiveBreakpoint}) {
    ${({ isRTL, iconsOnly }) => {
      if (isRTL) return 'padding-right: 45px;';
      else if (!iconsOnly) return 'padding-left: 45px;';
    }}
  }
`;

type SideMenuPropsType = SideMenuProps & HeaderHeightPropType;

export const SideMenu = styled(VeneerSideMenu)<SideMenuPropsType>`
  padding: 0px;

  & > div > button {
    margin-right: 8px;
    padding-left: 0px;
  }

  & > div {
    min-height: ${({ headerHeight }) => headerHeight};
  }

  ul > li > .vn-side-menu__item-link[data-selected] {
    color: rgb(2, 122, 174);
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
