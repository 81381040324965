import { UserInterfaceV1Type } from '@jarvis/shell-commons/dist/interface/v1/userInterface/UserInterfaceV1';
import mock from './mock';
import { UserDataInterfaceV1Type } from '@jarvis/shell-commons/dist/interface/v1/userInterface/types';

let isMocked = false;

export function enableMock(enableMock?: boolean) {
  isMocked = enableMock;
}

export default async function getCurrentUser(
  userInterface: UserInterfaceV1Type
): Promise<UserDataInterfaceV1Type> {
  if (isMocked) return mock;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (userInterface as any)._userData.get();
}
