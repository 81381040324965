// eslint-disable-next-line no-restricted-imports
import { DeprecatedLogoInterface } from '@veneer/core/dist/scripts/deprecated_logos/types';
import React, { useEffect, useState } from 'react';
import logger from 'src/interfaces/logger';
import * as T from './types';
import createId from 'src/utils/createId';

const LogoFactory: T.LogoFactoryType = ({
  logoReference,
  appearance = 'singlecolor',
  color,
  disabled,
  focusable,
  size = 36
}) => {
  const [Logo, setLogo] = useState<React.FC<DeprecatedLogoInterface>>();

  useEffect(() => {
    const filename = logoReference
      ?.replace(/([A-Z])([A-Z][a-z])|([a-z0-9])([A-Z])/g, '$1$3_$2$4')
      ?.replace(/(Logo_)/g, '')
      .toLowerCase();

    if (filename) {
      let lazyImport;

      // This specific logo haven't a folder, it in the hp_plus folder in the veneer lib
      if (filename === 'hp_plus_print_plans') {
        lazyImport = import(
          /*webpackMode: "lazy-once", webpackChunkName: "logo-bundle"*/ `@veneer/core/dist/esm/scripts/logos/hp_plus/${filename}`
        );
      } else {
        lazyImport = import(
          /*webpackMode: "lazy-once", webpackChunkName: "logo-bundle"*/ `@veneer/core/dist/esm/scripts/logos/${filename}/${filename}`
        );
      }

      lazyImport
        .then((logo) => {
          setLogo(logo?.default);
        })
        .catch((error) => {
          logger?.error?.('Error on fetching logo:', error);
        });
    }
  }, [logoReference]);

  if (Logo) {
    return (
      <Logo
        data-testid={createId(`logo`)}
        color={color}
        disabled={disabled}
        focusable={focusable}
        size={size}
        appearance={appearance}
      />
    );
  }

  return null;
};

export default LogoFactory;
