import React from 'react';
import * as S from './styles';
import * as T from './types';

const defaultSize = 36;

const Skeleton: T.SkeletonType = ({ circle, height, width }) => {
  function getPixelValue(value?: number) {
    if (typeof value === 'number' && value > 0) return `${value}px`;
    return `${defaultSize}px`;
  }

  return (
    <S.Container
      style={{
        width: getPixelValue(width),
        height: getPixelValue(height),
        borderRadius: `${circle ? 50 : 12}px`
      }}
    />
  );
};

export default Skeleton;
