const packageJson = require('../../package.json');

const packageName = packageJson.name;
const [orgName, projectName] = packageName.substring(1).split(/\/(.+)/);
const sanitizedPackageName = packageName.replace(/@/g, '').replace(/\//g, '-');

const projectNames = {
  orgName,
  projectName,
  sanitizedPackageName,
  packageName
};

exports.orgName = projectNames.orgName;
exports.projectName = projectNames.projectName;
exports.sanitizedPackageName = projectNames.sanitizedPackageName;
exports.packageName = projectNames.packageName;

module.exports = projectNames;
