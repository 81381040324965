/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useMemo } from 'react';

function useAssetImport<T extends System.Module>(assetReference: string) {
  const [asset, setAsset] = useState<T>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<any>();

  useEffect(() => {
    if (assetReference) {
      setIsLoading(true);
      System.import(assetReference)
        .then((response) => {
          setAsset(response as T);
        })
        .catch((error) => {
          console.error(error);
          setAsset(undefined);
          setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [assetReference]);

  const result = useMemo(
    () => ({
      asset,
      isLoading,
      error
    }),
    [asset, isLoading, error]
  );

  return result;
}

export default useAssetImport;
