import React, { useMemo } from 'react';
import { useRootContext } from 'src/contexts/Root';
import { useThemeContext } from 'src/contexts/Theme';
import urlOrPathNavigation from 'src/utils/urlOrPathNavigation';
import * as T from './types';
import * as S from './styles';

const InformationWidget: T.InformationWidgetType = ({
  text,
  url,
  customStyle
}) => {
  const { navigation, globalTranslate } = useRootContext();
  const { mode } = useThemeContext();

  function onAnchorClick(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) {
    event?.preventDefault();
    urlOrPathNavigation({
      navigation,
      urlOrPath: url?.value,
      newTab: url?.newTab
    });
  }

  const translatedText = useMemo(
    () => globalTranslate(text?.translationKey, text?.value),
    [globalTranslate, text?.translationKey, text?.value]
  );

  if (url?.value) {
    return (
      <S.AnchorText
        customStyle={customStyle}
        href={url?.value}
        {...(url?.newTab
          ? {
              target: '_blank',
              rel: 'noreferrer'
            }
          : {
              rel: 'noopener'
            })}
        onClick={onAnchorClick}
        themeMode={mode}
      >
        {translatedText}
      </S.AnchorText>
    );
  }
  return (
    <S.Text
      customStyle={customStyle}
      themeMode={mode}
    >
      {translatedText}
    </S.Text>
  );
};

export default InformationWidget;
