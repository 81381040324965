/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import { Tooltip } from '@veneer/core';
import * as S from './styles';
import * as T from './types';
import createId from 'src/utils/createId';

const SelectableButton: T.SelectableButtonType = ({
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  selected,
  children,
  tooltip,
  onMouseDown,
  appearance,
  themeMode,
  ...props
}) => {
  const [isActive, setIsActive] = useState(!!selected);
  const applyMouseUpEvent = !selected && isActive;

  useEffect(() => {
    setIsActive(!!selected);
  }, [selected]);

  useEffect(() => {
    if (applyMouseUpEvent) {
      const mouseUpHandler = () => {
        setIsActive(false);
      };
      window.addEventListener('mouseup', mouseUpHandler);

      return () => window.removeEventListener('mouseup', mouseUpHandler);
    }
  }, [applyMouseUpEvent]);

  const Wrapper = tooltip ? Tooltip : React.Fragment;
  const wrapperProps = useMemo(() => {
    if (tooltip) {
      return {
        content: tooltip,
        arrow: true,
        placement: 'bottom',
        useJsPositioning: true
      };
    }
    return {} as any;
  }, [tooltip]);

  const insertDataTestId = () => {
    if (props.index == undefined) {
      return createId(`more_organization`);
    } else {
      return createId(`organization_${props.index}`);
    }
  };

  return (
    <Wrapper {...wrapperProps}>
      <S.Container
        data-testid={insertDataTestId()}
        onMouseDown={(e) => {
          setIsActive(true);
          onMouseDown?.(e);
        }}
        themeMode={themeMode}
        {...{ ...props, isActive, appearance }}
      >
        <S.SideContentWrapper>
          {LeftIcon && (
            <LeftIcon
              filled={isActive}
              size={24}
            />
          )}
        </S.SideContentWrapper>
        <S.ChildrenWrapper>{children}</S.ChildrenWrapper>
        <S.SideContentWrapper>
          {RightIcon && (
            <RightIcon
              filled={isActive}
              size={24}
            />
          )}
        </S.SideContentWrapper>
      </S.Container>
    </Wrapper>
  );
};

export default SelectableButton;
