import styled from 'styled-components';
import tokens from '@veneer/tokens';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: ${tokens.layout.size3};
  flex-wrap: nowrap;
  padding: ${tokens.layout.size3} ${tokens.layout.size0};
`;
