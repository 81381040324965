import { isInternetExplorer } from 'src/utils/isInternetExplorer';
import styled from 'styled-components';
import tokens from '@veneer/tokens';
import { themeModeType } from 'src/types/commonPropertiesType';

let buttonColor: string = tokens.color.gray7;
let tertiaryColor: string = tokens.color.hpBlue6;
let activeBackgroundColor: string = 'rgba(2, 122, 174, 0.1)';
let activeIconColor: string = tokens.color.hpBlue8;
let backgroundHoverColor: string = 'rgba(33, 33, 33, 0.05)';

//TODO: update this style to use venner tokens
export const Container = styled.button<
  {
    isActive?: boolean;
    appearance?: string;
  } & themeModeType
>`
  border: none;
  background: none;
  cursor: pointer;
  padding: 8px;
  gap: 8px;
  border-radius: ${tokens.layout.cornerRadius2};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  max-width: 100%;
  color: ${buttonColor};
  font-size: ${tokens.typography.size2};
  position: relative;

  ${({ isActive, appearance, themeMode }) => {
    if (themeMode === 'contrast') {
      buttonColor = tokens.color.white;
      tertiaryColor = tokens.color.highContrastYellow;
      activeIconColor = tokens.color.white;
      activeBackgroundColor = 'rgba(255, 247, 0, 0.3)';
      backgroundHoverColor = 'rgba(255, 247, 0, 0.1)';
    }

    if (themeMode === 'dark') {
      buttonColor = tokens.color.white;
      tertiaryColor = tokens.color.hpBlue5;
      activeIconColor = tokens.color.white;
      activeBackgroundColor = 'rgba(58,180,235, 0.3)';
      backgroundHoverColor = 'rgba(58,180,235, 0.1)';
    }

    if (appearance === 'tertiary') {
      return `
        color: ${tertiaryColor};

        svg {
          color: ${tertiaryColor};
        }
      `;
    } else if (isActive) {
      return `
        background: ${activeBackgroundColor};
        color: ${buttonColor};
        svg {
          color: ${activeIconColor};
        }
      `;
    } else {
      return `
        :hover {
          background: ${backgroundHoverColor};
        }
        color: ${buttonColor};
      `;
    }
  }}
`;

export const ChildrenWrapper = styled.div`
  width: 100%;
  text-align: initial;
  ${() =>
    isInternetExplorer() &&
    `padding-left: ${tokens.layout.size3};
     padding-right: ${tokens.layout.size3};`}
`;

export const SideContentWrapper = styled.div`
  width: 24px;
  height: 24px;
`;
