import React from 'react';
import { useRootContext } from 'src/contexts/Root';
import Profile from './components/Profile';
import ProfileMenu from './components/ProfileMenu';
import * as T from './types';

// TODO: AvatarMenuWidget need to become a external widget and removed from the layout definition
const AvatarMenuWidget: T.AvatarMenuWidgetType = ({
  menuList,
  orgSelector,
  showOnlyMenus,
  criterionKey,
  hideOrgName
}) => {
  const { criterion } = useRootContext();
  const isLoggedIn = criterion.useReactCheck(React, {
    type: 'isLoggedIn',
    value: true
  });
  const customCriterionCheckValue = criterionKey
    ? criterion.useReactCheckByCriterionKey(React, criterionKey)
    : true;
  const isAllowedToRender = isLoggedIn && customCriterionCheckValue;

  if (isAllowedToRender) {
    if (showOnlyMenus) {
      return <Profile menuList={menuList} />;
    }

    return (
      <ProfileMenu
        menuList={menuList}
        orgSelector={orgSelector}
        hideOrgName={hideOrgName}
      />
    );
  }
  return null;
};

export default AvatarMenuWidget;
