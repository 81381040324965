import styled from 'styled-components';
import tokens from '@veneer/tokens';
import isSafariBrowser from 'src/utils/isSafariBrowser';
import * as T from './types';

const isSafari = isSafariBrowser();

type ContainerStyleType = {
  customStyles: T.SideMenuLayoutPropsType['properties']['customStyle'];
  responsiveBreakpoint: string;
  size: 'small' | 'medium' | 'large';
  isRTL: boolean;
  iconsOnly: boolean;
};

// todo there's an issue that height could be -> height: 100vh;
export const Container = styled.div<ContainerStyleType>`
  height: ${isSafari ? '-webkit-fill-available' : '100vh'};
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  transition: 500ms padding;
  overflow: hidden;
  background: ${({ customStyles }) =>
    customStyles?.backgroundColor || tokens.color.gray0};

  ul {
    padding: 0;
    margin: 0;
  }

  & > header {
    padding: 0 25px;
  }

  @media (min-width: calc(${({ responsiveBreakpoint }) =>
      responsiveBreakpoint} + 1px)) {
    ${({ isRTL, size, iconsOnly }) => {
      const width = iconsOnly ? '60px' : size === 'large' ? '260px' : '144px';

      if (isRTL) return `padding-right: ${width} !important;`;
      return `padding-left:  ${width} !important;`;
    }}
  }

  @media (max-width: ${({ responsiveBreakpoint }) => responsiveBreakpoint}) {
    ${({ isRTL, iconsOnly }) => {
      const mobileWidth = iconsOnly ? '60px' : '0px';

      if (isRTL) return `padding-right: ${mobileWidth} !important;`;
      return `padding-left:  ${mobileWidth} !important;`;
    }}
  }
`;
export const Content = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: ${tokens.layout.size8};
`;

export const ChildrenWrapper = styled.div``;
