import React, { useMemo, useState, useCallback, useEffect } from 'react';
import RootContext, {
  RootContextType,
  RootUseContextReturnType
} from './RootContext';

const RootProvider = ({
  children,
  localizationResources,
  platform,
  ...props
}: RootContextType) => {
  const { localization } = props;
  const [commonsGlobalTranslate, setCommonsGlobalTranslate] =
    useState<RootUseContextReturnType['globalTranslate']>();

  const TranslatorProvider = useMemo(
    () => localization.getReactTranslatorProvider(React),
    [localization]
  );

  useEffect(() => {
    localization
      ?.getGlobalTranslatorFunction?.()
      ?.then((t) => setCommonsGlobalTranslate(() => t));
  }, [localization]);

  const globalTranslate = useCallback<
    RootUseContextReturnType['globalTranslate']
  >(
    (...args: Parameters<RootUseContextReturnType['globalTranslate']>) => {
      return commonsGlobalTranslate?.(...args) || '';
    },
    [commonsGlobalTranslate]
  );

  return (
    <RootContext.Provider value={{ ...props, globalTranslate, platform }}>
      <TranslatorProvider resources={localizationResources}>
        {children}
      </TranslatorProvider>
    </RootContext.Provider>
  );
};

export default RootProvider;
