import { isInternetExplorer } from 'src/utils/isInternetExplorer';
import styled from 'styled-components';
import tokens from '@veneer/tokens';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.layout.size5};

  ${() =>
    isInternetExplorer() &&
    `& > *:not(:first-child) {
      margin-left: ${tokens.layout.size2};
    }
    & > *:not(:last-child) {
      margin-right: ${tokens.layout.size2};
    }`}
`;
