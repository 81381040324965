import styled from 'styled-components';
import { ContextualMenu } from '@veneer/core';

// TODO: this sounds weird, we should double check this.
export const Menu = styled(ContextualMenu)`
  && {
    .css-u4cc7q {
      margin-right: 27px;
    }
  }
`;
