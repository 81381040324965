import React, { UIEvent, useMemo } from 'react';
import { Menu } from './styles';
import AvatarButton from '../AvatarButton';
import { useRootContext } from 'src/contexts/Root';
import * as T from './types';
import useAvatarMenuListFilter from '../../hooks/useAvatarMenuListFilter';
import { OptionInterface, OptionValue } from '@veneer/core';
import { AvatarMenu } from '../../types';

const Profile: T.ProfileMenuType = ({ menuList: menuListProp }) => {
  const { menusWithLogout } = useAvatarMenuListFilter(menuListProp);
  const { navigation, sessionInterface } = useRootContext();

  const menuList = useMemo<OptionInterface[]>(
    () =>
      menusWithLogout?.map?.((menu) => ({
        value: menu?.text?.value,
        path: menu?.path
      })),
    [menusWithLogout]
  );

  const getPathByOptionValue = (
    menus: AvatarMenu[],
    optionValue: OptionValue
  ) => {
    const foundMenu = menus?.find?.(
      (menu) => menu?.text?.value === optionValue
    );
    return foundMenu?.path || false;
  };

  const handleClick = async (
    _event: UIEvent<HTMLElement>,
    option: OptionInterface<OptionValue>
  ) => {
    if (option?.value && menusWithLogout) {
      const optionPath = getPathByOptionValue(menusWithLogout, option.value);
      if (optionPath) {
        if (optionPath == '/loggedout') {
          await sessionInterface.logout();
        } else {
          navigation.push(optionPath);
        }
      }
    }
  };

  return (
    <Menu
      onClick={handleClick}
      options={menuList}
    >
      <div>
        <AvatarButton />
      </div>
    </Menu>
  );
};

export default Profile;
