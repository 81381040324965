import styled from 'styled-components';
import tokens from '@veneer/tokens';
import * as T from './types';
import { themeModeType } from 'src/types/commonPropertiesType';
import { getColor, getFontSize } from 'src/components/factories/utils/styles';

type CustomProperties = {
  customStyle: T.InformationWidgetPropsType['customStyle'];
};

export const Text = styled.p<CustomProperties & themeModeType>`
  margin: ${tokens.layout.size0} ${tokens.layout.size5};
  font-size: ${({ customStyle }) => getFontSize(customStyle?.fontSize)};
  color: ${({ customStyle, themeMode }) =>
    getColor({
      defaultColor: tokens.color.gray5,
      defaultHighContrastColor: tokens.color.highContrastGray,
      themeMode: themeMode,
      customColor: customStyle?.color
    })};
`;

export const AnchorText = styled.a<CustomProperties & themeModeType>`
  margin: ${tokens.layout.size0} ${tokens.layout.size5};
  font-size: ${({ customStyle }) => getFontSize(customStyle?.fontSize)};
  color: ${({ customStyle, themeMode }) =>
    getColor({
      defaultColor: tokens.color.hpBlue5,
      defaultHighContrastColor: tokens.color.highContrastYellow,
      themeMode: themeMode,
      customColor: customStyle?.color
    })};
`;
