import { DirectionProvider } from '@veneer/core';
import { ThemeProvider } from '@veneer/theme/';
import React, { useEffect, useMemo, useState } from 'react';
import { App } from '../src';
import RootProvider from '../src/contexts/Root/RootProvider';
import { InterfacesType, ShellRootComponentProps } from '../src/types/shell';
import projectNames from './configs/projectNames';
import { getPlatform } from 'src/utils/jweb/Platform';

declare global {
  interface Window {
    Shell: {
      v1: InterfacesType;
    };
  }
}

export default function Root({
  properties,
  ...props
}: ShellRootComponentProps) {
  const [platform, setPlatform] = useState('');
  // You can find all Shell´s props here. See "src/types/shell" for more details.
  // TODO: Declare the Shell type in shell-commons, then we can remove the eslint rule
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const shell = (window as any).Shell.v1 as InterfacesType;

  const localization = shell?.localization;
  const directionValue = localization?.useReactGetLanguageDirection?.(React);

  const { themeProviderProps, userThemeMode } = useMemo(() => {
    return {
      themeProviderProps: shell?.theme?.getThemeProviderProperties?.(),
      userThemeMode: shell?.theme?.getUserThemeMode?.()
    };
  }, [shell?.theme]);

  useEffect(() => {
    const fetchPlatform = async () => {
      const platform = await getPlatform();
      setPlatform(platform);
    };

    fetchPlatform();
  }, []);

  return (
    <section
      className={`${projectNames.sanitizedPackageName}`}
      id={projectNames.packageName}
    >
      <DirectionProvider direction={directionValue}>
        <ThemeProvider
          {...themeProviderProps}
          mode={userThemeMode}
        >
          <RootProvider
            {...{ properties, localization, ...shell, ...props, platform }}
          >
            <App properties={properties}>{props.children}</App>
          </RootProvider>
        </ThemeProvider>
      </DirectionProvider>
    </section>
  );
}
