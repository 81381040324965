import React, { useMemo } from 'react';
import Skeleton from './components/Skeleton';
import VeneerLoader from './components/VeneerLoader';
import * as T from './types';

const LoaderFactory: T.LoaderFactoryType = ({
  appearance,
  properties,
  defaultAppearance = 'circular'
}) => {
  const appearanceValue = useMemo(() => {
    switch (appearance) {
      case 'circular':
        return 'circular';
      case 'linear':
        return 'linear';
      case 'skeleton':
        return 'skeleton';
      default:
        return defaultAppearance;
    }
  }, [appearance, defaultAppearance]);

  if (appearanceValue === 'skeleton') {
    return (
      <Skeleton
        circle={properties?.circle}
        height={properties?.height}
        width={properties?.width}
      />
    );
  }

  return <VeneerLoader appearance={appearanceValue} />;
};

export default LoaderFactory;
