import React, { useState, useEffect } from 'react';
import { Avatar, ProgressIndicator, Tooltip } from '@veneer/core';
import getCurrentUser from 'src/services/usermgt/getCurrentUser';
import createId from 'src/utils/createId';
import logger from 'src/interfaces/logger';
import { useRootContext } from 'src/contexts/Root';
import * as S from './styles';
import * as T from './types';

type InitialsStatusType = 'launching' | 'loading' | 'success';
type InitialsType = {
  value: string;
  status: InitialsStatusType;
};

const AvatarButton = ({
  givenName,
  familyName,
  forceLoading,
  ...props
}: T.AvatarButtonPropsType) => {
  const { authProvider, stack, userInterface } = useRootContext();
  const [initials, setInitials] = useState<InitialsType>({
    value: '',
    status: 'launching'
  });
  const allowRequest = initials?.status === 'launching';
  const showLoader = !(initials?.value?.length > 0);

  useEffect(() => {
    function getInitialsValue(firstName?: string, secondName?: string) {
      const firstLetter = firstName?.trim()?.[0]?.toUpperCase() || '';
      const secondLetter = secondName?.trim()?.[0]?.toUpperCase() || '';

      return firstLetter + secondLetter;
    }

    async function fetchCurrentUser(): Promise<string> {
      if (givenName && familyName) {
        return getInitialsValue(givenName, familyName);
      } else {
        const user = await getCurrentUser(userInterface).catch((err) => {
          logger?.error?.(err);
        });

        const firstName = user && user?.givenName;
        const lastName = user && user?.familyName;

        return getInitialsValue(firstName, lastName);
      }
    }

    if (!forceLoading && allowRequest) {
      setInitials((prev) => ({ ...prev, status: 'loading' }));

      fetchCurrentUser().then((value) =>
        setInitials({ value, status: 'success' })
      );
    }
  }, [stack, authProvider, givenName, familyName, forceLoading, allowRequest]);

  return (
    <S.Container
      data-testid={createId('avatar-button')}
      {...props}
    >
      <Tooltip
        id="tooltip_avatarbutton"
        content={
          <>
            {givenName}&nbsp;{familyName}
          </>
        }
        placement="bottom"
        arrow
        contentHideDelayOnHover={300}
        contentShowDelayOnHover={300}
      >
        <Avatar
          data-testid={createId('avatar_name')}
          {...(showLoader || forceLoading
            ? { icon: <ProgressIndicator data-testid={createId('loader')} /> }
            : { label: initials?.value })}
          size={36}
        />
      </Tooltip>
    </S.Container>
  );
};

export default AvatarButton;
