import React from 'react';
import createId from 'src/utils/createId';
import LazyComponentFactory from '../factories/LazyComponentFactory';
import * as T from './types';
import * as S from './styles';

const Footer: T.FooterType = ({
  asset,
  leftContent,
  rightContent,
  transparentBackground,
  height
}) => {
  if (asset?.assetReference) {
    return (
      <LazyComponentFactory
        {...asset}
        defaultAppearance="linear"
      />
    );
  }

  return (
    <DefaultFooter
      data-testid={createId('footer')}
      {...{ leftContent, rightContent, transparentBackground, height }}
    />
  );
};

const DefaultFooter: T.DefaultFooterType = ({
  leftContent,
  rightContent,
  transparentBackground,
  height
}) => {
  return (
    <S.PortalFooter
      appearance={'ultraminimal'}
      customStyle={{
        background: transparentBackground ? 'none' : undefined,
        height
      }}
      data-testid={createId('footer')}
      mainContent={{
        leadingArea: leftContent,
        trailingArea: rightContent
      }}
    />
  );
};

export default Footer;
