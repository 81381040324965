/* eslint-disable no-restricted-imports */
import {
  IconChevronLeft,
  IconPerson,
  IconChevronRight,
  IconBriefcase,
  IconHome,
  IconCheckmark,
  IconEllipsis,
  IconPlusCircle,
  IconPrinter,
  IconPeople,
  IconGear,
  IconHelp,
  IconTruck,
  IconGlobe,
  IconLightbulb,
  IconHandStars,
  IconPersonCircle
} from '@veneer/core';

type DefaultIconsType = {
  [key: string]: typeof IconChevronLeft;
};

export const defaultIcons: DefaultIconsType = {
  IconChevronLeft,
  IconPerson,
  IconChevronRight,
  IconBriefcase,
  IconHome,
  IconCheckmark,
  IconEllipsis,
  IconPlusCircle,
  IconPrinter,
  IconPeople,
  IconGear,
  IconHelp,
  IconTruck,
  IconGlobe,
  IconLightbulb,
  IconHandStars,
  IconPersonCircle
};

export const defaultIconsAvailable = [
  'IconChevronLeft',
  'IconPerson',
  'IconChevronRight',
  'IconBriefcase',
  'IconHome',
  'IconCheckmark',
  'IconEllipsis',
  'IconPlusCircle',
  'IconPrinter',
  'IconPeople',
  'IconGear',
  'IconHelp',
  'IconTruck',
  'IconGlobe',
  'IconLightbulb',
  'IconHandStars',
  'IconPersonCircle'
];
