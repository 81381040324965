/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import * as S from './styles';
import {
  useToast,
  Button,
  Modal,
  ProgressIndicator,
  TextBox
} from '@veneer/core';
import SelectableButton from 'src/components/SelectableButton';
import {
  getCropedAccountName,
  getCropedResourceId
} from '../../utils/cropText';
import * as T from './types';
import { useRootContext } from 'src/contexts/Root';
import { useThemeContext } from 'src/contexts/Theme';
import createId from 'src/utils/createId';
import AuthContextEnum from 'src/enums/AuthContextEnum';
import IconFactory from 'src/components/factories/IconFactory';
import { CustomIconsType } from '../../../../types';

const firstDomainsMaxSize = 4;

const IconChevronRightFc = (filled?: boolean) => (
  <IconFactory
    filled={filled}
    iconReference="IconChevronRight"
  />
);

const IconCheckmarkFc = (filled?: boolean) => (
  <IconFactory
    filled={filled}
    iconReference="IconCheckmark"
  />
);

type SelectLeftIconProps = {
  tenantType?: string;
  defaultIcon?: string;
  filled?: boolean;
  customIcons?: CustomIconsType[];
};

const selectedLeftIcon = ({
  tenantType,
  defaultIcon,
  filled,
  customIcons = []
}: SelectLeftIconProps) => {
  const findType = customIcons.filter(
    (typeProp) => typeProp.type === tenantType
  );

  // # rule 1: contains a type with customized icon
  if (tenantType && findType.length) {
    return (
      <IconFactory
        filled={filled}
        iconReference={findType[0].icon}
      />
    );
  }

  let iconName = 'IconBriefcase'; // default icon
  // # rule 2: contains a type with non-customized icon and custom default icon
  if (defaultIcon) iconName = defaultIcon;

  // # rule 3: will be used the default shell icon
  return (
    <IconFactory
      filled={filled}
      iconReference={iconName}
    />
  );
};

const selectedRightIcon = (
  isSelected: boolean,
  useChevron?: boolean,
  filled?: boolean
) => {
  if (isSelected) {
    return IconCheckmarkFc(filled);
  }
  if (useChevron) {
    return IconChevronRightFc(filled);
  }
  return null;
};

const Domains = ({
  showAllDomains,
  setShowAllDomains,
  setIgnoreClick,
  onModalToggle,
  orgCreation,
  filled,
  defaultIcon,
  customIcons,
  useChevron
}: T.DomainsPropsType) => {
  const veneerToast = useToast();
  const [newDomainName, setNewDomainName] = useState('');
  const [changingDomain, setChangingDomain] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [{ firstDomains, allDomains }, setDomainsList] = useState({
    firstDomains: [] as T.TenantVisualizationType[],
    allDomains: [] as T.TenantVisualizationType[]
  });
  const [isCreatingDomain, setIsCreatingDomain] = useState(false);
  const { localization, orgSelector, tenantHandlerInterface } =
    useRootContext();
  const isDomainCreationEnabled = orgCreation?.enable;
  const { t } = localization.useReactTranslatorHook();
  const { mode } = useThemeContext();
  useEffect(() => {
    onModalToggle(showModal);
  }, [onModalToggle, showModal]);

  // TODO: Refactor this code
  const updateDomainList = useCallback(async () => {
    const allDomains =
      (
        (await tenantHandlerInterface?.getTenantList?.({
          authContext: AuthContextEnum.tenant,
          refresh: true
        })) || []
      ).sort((a, b) => a?.name.localeCompare(b?.name)) || [];

    const currentTenantIndex = allDomains.findIndex(
      (t) =>
        t.id === tenantHandlerInterface?.getTenantId(AuthContextEnum.tenant)
    );
    const currentTenant = allDomains.splice(currentTenantIndex, 1)?.[0];
    currentTenant && allDomains.unshift(currentTenant);
    const firstDomains: typeof allDomains = [];

    (() => {
      for (const item of allDomains) {
        if (firstDomains.length >= firstDomainsMaxSize) {
          break;
        }
        firstDomains.push(item);
      }
    })();

    setDomainsList({ firstDomains, allDomains });
  }, [tenantHandlerInterface]);

  useEffect(() => {
    updateDomainList();
  }, [updateDomainList]);
  const domainsToMap = showAllDomains ? allDomains : firstDomains;

  async function createDomain() {
    setIsCreatingDomain(true);
    // FIXME: Fix commons type definitions for createTenant
    await orgSelector
      ?.createTenant?.({
        accountName: newDomainName
      } as any)
      .then((tenant: T.DomainType) => {
        updateDomainList();
        veneerToast?.addToast?.({
          id: 'createDomainPositiveToast',
          type: 'positive',
          text: `${getCropedAccountName(
            tenant?.accountName || (tenant as T.DomainType)?.description
          )} ${getCropedResourceId(tenant?.nodeId)} ${t(
            'nav.domain.success',
            'was successfully created.'
          )}`
        });
        closeModal();
      })
      .catch(() => {
        veneerToast?.addToast?.({
          id: 'createDomainNegativeToast',
          type: 'negative',
          // TODO: Check how we are going to localize it, should it come from property?
          text: t('nav.domain.unable-create', 'Unable to create organization.'),
          action: (
            <S.ToastAction
              onClick={() => {
                setIgnoreClick(true);
                createDomain();
              }}
            >
              {/* TODO: Check how we are going to localize it, should it come from property? */}
              {t('nav.domain.try-again', 'Try Again')}
            </S.ToastAction>
          )
        });
      })
      .finally(() => setIsCreatingDomain(false));
  }

  function closeModal() {
    setIgnoreClick(true);
    setNewDomainName('');
    setShowModal(false);
  }

  if (changingDomain) {
    return <ProgressIndicator appearance="linear" />;
  }

  return (
    <S.Container data-testid={createId('organization_list')}>
      <S.ContainerWithScrollbar>
        {domainsToMap?.map?.(({ name, id, type }, index) => {
          const orgName = name;
          const selected =
            tenantHandlerInterface?.getTenantId(AuthContextEnum.tenant) === id;
          return (
            <SelectableButton
              key={id}
              tooltip={`${orgName} (${id})`}
              leftIcon={() =>
                selectedLeftIcon({
                  tenantType: type,
                  defaultIcon: defaultIcon,
                  filled: selected && filled,
                  customIcons: customIcons
                })
              }
              rightIcon={() => selectedRightIcon(selected, useChevron, filled)}
              selected={selected}
              index={index}
              themeMode={mode}
              onClick={() => {
                const currentTenantId = tenantHandlerInterface?.getTenantId(
                  AuthContextEnum.tenant
                );
                if (currentTenantId === id) {
                  return;
                }
                setChangingDomain(true);
                setIgnoreClick(true);
                tenantHandlerInterface
                  .setTenant({
                    tenantId: id,
                    authContext: AuthContextEnum.tenant,
                    useCustomBehavior: true
                  })
                  .finally(() => {
                    setChangingDomain(false);
                  });
              }}
            >
              {getCropedAccountName(orgName)} {getCropedResourceId(id)}
            </SelectableButton>
          );
        })}
      </S.ContainerWithScrollbar>
      {!showAllDomains && (
        <>
          {allDomains?.length > firstDomainsMaxSize && (
            <SelectableButton
              leftIcon={() => <IconFactory iconReference="IconEllipsis" />}
              onClick={() => {
                setIgnoreClick(true);
                setShowAllDomains(true);
              }}
              themeMode={mode}
            >
              {/* TODO: Check how we are going to localize it, should it come from property? */}
              {t('nav.domain.more', 'More Organizations')}
            </SelectableButton>
          )}
          {isDomainCreationEnabled && (
            <SelectableButton
              leftIcon={() => <IconFactory iconReference="IconPlusCircle" />}
              appearance="tertiary"
              onClick={() => setShowModal(true)}
              themeMode={mode}
            >
              {/* TODO: Check how we are going to localize it, should it come from property? */}
              {t('nav.domain.add-new-organization', 'Add New Organization')}
            </SelectableButton>
          )}
        </>
      )}
      <Modal
        // TODO: Check how we are going to localize it, should it come from property?
        title={t('nav.domain.create', 'Create a new organization')}
        onClose={closeModal}
        show={showModal}
      >
        <S.CreateDomainModalWrapper>
          <p>
            {t(
              'nav.domain.paragraph',
              'An admin account can oversee and manage multiple organizations, each representing a different business. Create a new organization by entering the business name below.'
            )}
          </p>
          <TextBox
            value={newDomainName}
            // TODO: Check how we are going to localize it, should it come from property?
            label={t('nav.domain.name', 'Enter organization name')}
            onChange={(e) => {
              setNewDomainName(e);
            }}
          />
          <S.ModalButtonWrapper>
            <Button
              appearance="secondary"
              onClick={closeModal}
            >
              {/* TODO: Check how we are going to localize it, should it come from property? */}
              {t('nav.domain.cancel', 'Cancel')}
            </Button>
            <Button
              disabled={!(newDomainName?.length > 0) || isCreatingDomain}
              appearance="primary"
              onClick={createDomain}
            >
              {isCreatingDomain ? (
                <ProgressIndicator
                  color="gray4"
                  appearance="circular"
                />
              ) : (
                // TODO: Check how we are going to localize it, should it come from property?
                t('nav.domain.save', 'Save')
              )}
            </Button>
          </S.ModalButtonWrapper>
        </S.CreateDomainModalWrapper>
      </Modal>
    </S.Container>
  );
};

export default Domains;
