// eslint-disable-next-line no-restricted-imports
import { jWebReady } from '@jarvis/jweb-core';

// Since the return type is a string here is the possible strings to be returned:
// https://github.azc.ext.hp.com/jarvis/jweb/blob/develop/core/src/plugins/device.ts#L18
export const getPlatform = async () => {
  const jweb = await jWebReady;
  if (!jweb.isPluginAvailable('Device')) return jweb.getPlatform();
  const DeviceInfo = await jweb.Plugins.Device.getInfo();
  return DeviceInfo.platform;
};
