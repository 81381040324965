import React from 'react';
import { useRootContext } from 'src/contexts/Root';
import * as S from './styles';
import * as T from './types';

const AppNameTextComponent: T.AppNameTextComponentType = ({
  value,
  translationKey
}) => {
  const { globalTranslate } = useRootContext();

  return <S.Container>{globalTranslate(translationKey, value)}</S.Container>;
};

export default AppNameTextComponent;
