import React, { MouseEvent, useMemo } from 'react';
import { useRootContext } from 'src/contexts/Root';
import * as T from './types';
import * as S from './styles';
import createId from 'src/utils/createId';

const maxBreadcrumSize = 5;

// TODO: Make possible to use breadcrumb as a widget to reuse in other layouts and if possible to become a external widget
const Breadcrumbs: T.BreadcrumbsType = ({ responsiveBreakpoint }) => {
  const {
    navigation,
    breadcrumbs: breadcrumbsInterface,
    globalTranslate
  } = useRootContext();
  const breadcrumbInterfaceItems =
    breadcrumbsInterface?.useReactListener?.(React);

  const breadcrumbItems = useMemo(() => {
    const result =
      breadcrumbInterfaceItems?.map?.(({ url, translationKey, key, text }) => {
        return {
          key,
          url,
          text: globalTranslate(translationKey, text) || '',
          onClick: (event: MouseEvent) => {
            event.preventDefault();
            if (url) {
              navigation.push(url);
            }
          }
        };
      }) || [];

    const resultSize = result?.length || 0;
    if (resultSize > maxBreadcrumSize) {
      return [{ text: '...' }, result[resultSize - 2], result[resultSize - 1]];
    } else {
      return result;
    }
  }, [breadcrumbInterfaceItems, globalTranslate, navigation]);

  return (
    <S.CustomBreadcrumb
      responsiveBreakpoint={responsiveBreakpoint as string}
      data-testid={createId(`breadcrumb_items`)}
      items={breadcrumbItems}
      truncateText={true}
    />
  );
};

export default Breadcrumbs;
