import { isInternetExplorer } from 'src/utils/isInternetExplorer';
import styled from 'styled-components';
import { ProfileMenuCommonWrapper } from '../../styles';
import tokens from '@veneer/tokens';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

export const UserDataAndMenuListWrapper = styled(ProfileMenuCommonWrapper)``;

export const UserDataWrapper = styled(ProfileMenuCommonWrapper)`
  gap: 4px;
`;

export const UserNameText = styled.p`
  font-size: ${tokens.typography.size2};
  line-height: ${tokens.typography.lineHeight2};

  overflow: hidden;
  text-overflow: ellipsis;
  width: 230px;
  max-width: 100%;
  white-space: nowrap;
  ${() => isInternetExplorer() && `margin-bottom: ${tokens.layout.size2};`}
`;

export const UserEmailText = styled.p`
  font-size: ${tokens.typography.size1};
  line-height: ${tokens.typography.lineHeight2};
  width: 230px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MenuListWrapper = styled(ProfileMenuCommonWrapper)``;

export const MenuItem = styled.p`
  border: none;
  background: none;
  outline: 0;
  padding: 0;
  margin: 0;
  color: #027aae;
  cursor: pointer;
  font-size: ${tokens.typography.size2};
  line-height: ${tokens.typography.lineHeight2};
`;
