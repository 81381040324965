import { NavigationType } from 'src/types/shell';

export default function urlOrPathNavigation(options: {
  navigation?: NavigationType;
  urlOrPath?: string;
  newTab?: boolean;
}) {
  const urlValue = options?.urlOrPath;
  if (urlValue) {
    const isPathname = urlValue?.trim?.()?.startsWith?.('/');

    if (options?.newTab && isPathname) {
      window.open(urlValue.split('/')[1], '_blank')?.focus?.();
      return;
    }

    if (options?.newTab) {
      window.open(urlValue, '_blank')?.focus?.();
      return;
    }
    if (!options?.navigation) {
      window.location.href = urlValue;
    }

    if (isPathname) {
      options?.navigation?.push?.(urlValue);
    } else {
      options?.navigation?.redirect?.(urlValue);
    }
  }
}
