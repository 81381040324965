import React, { useEffect, useMemo, useState } from 'react';
import { useRootContext } from 'src/contexts/Root';
import createId from 'src/utils/createId';
import AvatarButton from '../AvatarButton';
import {
  getCropedAccountName,
  getCropedResourceId
} from '../ProfileMenu/utils/cropText';
import { ProgressIndicator, Tooltip } from '@veneer/core';
import { useDirectionContext } from 'src/contexts/Direction';
import { useThemeContext } from 'src/contexts/Theme';
import AuthContextEnum from 'src/enums/AuthContextEnum';
import { triggerAvatarIconEvent } from 'src/utils/analytics';

import * as S from './styles';
import * as T from './types';

const OrgAvatarButton: T.OrgAvatarButtonType = ({
  user,
  setShowModal,
  showModal,
  hideOrgName
}) => {
  const { tenantHandlerInterface } = useRootContext();
  const [currentTenant, setCurrentTenannt] =
    useState<T.TenantVisualizationType>();
  const [isLoading, setIsLoading] = useState(true);
  const { isRTL } = useDirectionContext();
  const { mode } = useThemeContext();

  useEffect(() => {
    if (tenantHandlerInterface.isEnabled()) {
      const currentTenant = tenantHandlerInterface?.getTenantData({
        authContext: AuthContextEnum.tenant
      });
      currentTenant && setCurrentTenannt(currentTenant);
    }
    setIsLoading(false);
  }, [tenantHandlerInterface]);

  const croppedOrgName = useMemo(() => {
    if (currentTenant?.name && currentTenant?.id) {
      return (
        getCropedAccountName(currentTenant?.name) +
        ' ' +
        getCropedResourceId(currentTenant?.id)
      );
    }
  }, [currentTenant]);

  const isOrgAvatarButtonShowable = !!(
    tenantHandlerInterface?.isEnabled?.() &&
    croppedOrgName &&
    !hideOrgName
  );

  return (
    <S.Container
      data-testid={createId('organization_avatar_menu')}
      isOrgAvatarButtonShowable={isOrgAvatarButtonShowable}
      isRTL={isRTL}
    >
      {isLoading ? (
        <ProgressIndicator data-testid={createId('loader')} />
      ) : (
        isOrgAvatarButtonShowable && (
          <Tooltip
            id="tooltip_orgname"
            content={
              <>
                {currentTenant?.name}
                <br />({currentTenant?.id})
              </>
            }
            placement="bottom"
            arrow
            contentHideDelayOnHover={300}
            contentShowDelayOnHover={300}
          >
            <S.OrgName
              data-testid={createId('org_name')}
              isRTL={isRTL}
              themeMode={mode}
            >
              {croppedOrgName}
            </S.OrgName>
          </Tooltip>
        )
      )}
      <AvatarButton
        forceLoading={!user}
        givenName={user?.givenName}
        familyName={user?.familyName}
        data-testid={createId('avatar_menu')}
        onClick={() => {
          triggerAvatarIconEvent();
          setShowModal(!showModal);
        }}
      />
    </S.Container>
  );
};

export default OrgAvatarButton;
