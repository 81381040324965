import { isInternetExplorer } from 'src/utils/isInternetExplorer';
import styled from 'styled-components';
import tokens from '@veneer/tokens';

// TODO: check if this override is really necessary.
export const Container = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  margin-right: 0px !important;
  ${() =>
    isInternetExplorer() && `margin-right: ${tokens.layout.size3} !important;`}
`;
