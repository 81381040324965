import React, { useMemo } from 'react';
import { useRootContext } from 'src/contexts/Root';
import urlOrPathNavigation from 'src/utils/urlOrPathNavigation';
import { Button } from '@veneer/core';
import * as T from './types';

const ButtonWidget: T.ButtonWidgetType = ({
  appearance = 'tertiary',
  url,
  text,
  small
}) => {
  const { navigation, globalTranslate } = useRootContext();

  function handleClick() {
    urlOrPathNavigation({
      navigation,
      urlOrPath: url?.value,
      newTab: url?.newTab
    });
  }

  const translatedText = useMemo(
    () => globalTranslate(text?.translationKey, text?.value),
    [globalTranslate, text?.translationKey, text?.value]
  );

  return (
    <Button
      onClick={handleClick}
      appearance={appearance}
      small={small}
    >
      {translatedText}
    </Button>
  );
};

export default ButtonWidget;
