import React from 'react';
import AvatarButton from '../../../AvatarButton';
import { ProgressIndicator, Tooltip } from '@veneer/core';
import * as S from './styles';
import * as T from './types';
import { useRootContext } from 'src/contexts/Root';
import createId from 'src/utils/createId';
import { triggerAccountProfileEvent } from 'src/utils/analytics';

const UserData: T.UserDataType = ({ menuList, user }) => {
  const { globalTranslate, navigation } = useRootContext();

  if (!user) {
    return <ProgressIndicator appearance="linear" />;
  }

  return (
    <S.Container>
      <AvatarButton
        forceLoading={!user}
        givenName={user?.givenName}
        familyName={user?.familyName}
      />
      <S.UserDataAndMenuListWrapper>
        <S.UserDataWrapper>
          <Tooltip
            id="tooltip_fullname"
            content={
              <>
                {user?.givenName}&nbsp;{user?.familyName}
              </>
            }
            placement="bottom"
            arrow
            contentHideDelayOnHover={300}
            contentShowDelayOnHover={300}
          >
            <S.UserNameText data-testid={createId('user-name')}>
              {user?.givenName}&nbsp;{user?.familyName}
            </S.UserNameText>
          </Tooltip>
          <Tooltip
            id="tooltip_email"
            content={<>{user?.email}</>}
            placement="bottom"
            arrow
            contentHideDelayOnHover={300}
            contentShowDelayOnHover={300}
          >
            <S.UserEmailText data-testid={createId('user-email')}>
              {user?.email}
            </S.UserEmailText>
          </Tooltip>
        </S.UserDataWrapper>
        <S.MenuListWrapper>
          {menuList?.map?.(({ path, text }, index) => (
            <S.MenuItem
              data-testid={createId(
                `${String(text?.value).replace(' ', '-').toLowerCase()}`
              )}
              key={`${text?.value}-${index}`}
              onClick={() => {
                triggerAccountProfileEvent();
                navigation.push(path);
              }}
            >
              {globalTranslate(text?.translationKey, text?.value)}
            </S.MenuItem>
          ))}
        </S.MenuListWrapper>
      </S.UserDataAndMenuListWrapper>
    </S.Container>
  );
};

export default UserData;
