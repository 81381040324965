import { createContext } from 'react';
import { InterfacesType, ShellCommonMfeProps } from 'src/types/shell';
import { PromiseReturnType } from 'src/types/typeHandlers';
import { TranslatorFunctionResourcePropType } from '@jarvis/shell-commons/dist/services/localizationService/ILocalizationTranslatorService';
import { LocalizationInterfaceType } from '@jarvis/shell-commons/dist/interface/v1/localization/types';
import { ConditionalFunctionChildren } from 'src/types/commonPropertiesType';
import { SideMenuLayoutFunctionalChildrenPropsType } from 'src/layouts/SideMenuLayout/types';

export type RootContextType = {
  children?: ConditionalFunctionChildren<SideMenuLayoutFunctionalChildrenPropsType>;
  localizationResources?: TranslatorFunctionResourcePropType;
  platform: string;
} & ShellCommonMfeProps &
  InterfacesType;

export type GlobalTranslateType = PromiseReturnType<
  LocalizationInterfaceType['createTranslatorFunction']
>;

export type RootUseContextReturnType = RootContextType & {
  globalTranslate: GlobalTranslateType;
};

const RootContext = createContext({} as RootUseContextReturnType);

export default RootContext;
