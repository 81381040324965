import React from 'react';
import WidgetFactory from 'src/components/factories/WidgetFactory';
import * as S from './styles';
import * as T from './types';

const WidgetListFactory: T.WidgetListFactoryType = ({ widgetList }) => {
  return (
    <S.Container>
      {widgetList?.map?.(({ key, ...props }) => (
        <WidgetFactory
          {...props}
          key={key}
        />
      ))}
    </S.Container>
  );
};

export default WidgetListFactory;
